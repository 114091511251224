import {
  Addition,
  CubeTest,
  Excipient,
  ExcipientEffect,
  Extra,
  Ingredient,
  ResourceType,
  SieveSize,
  SieveTest
} from "../types";
import {calculateWcf} from "../components/Sampling/CubeTestWcfStep";
import {format} from "react-numberinput-formatter";

export function getRecipeDensity(ingredients: any[]) {
  let result=ingredients.reduce((t, r) => t += r.amount, 0)
  if(result>0) result=Math.round(result * 10) / 10
  return result
}

export function getBinderTotal(ingredients: any[], hasAttest: boolean) {
  return ingredients.reduce((total, r) => {
    if (r.resource.type === ResourceType.Cement) {
      total += r.amount;
    } else if (r.resource.type === ResourceType.Filler) {
      let amount = r.amount;
      if (hasAttest && r.kFactor && r.attestPercentage) {
        const cementTotal = getCementTotal(ingredients);
        const max = cementTotal * (r.attestPercentage / 100);
        if (r.amount > max) {
          amount = max;
        }
      }
      total += amount * (r.kFactor || 0);
    }
    return total;
  }, 0);
}

export function getCementTotal(ingredients: any[]) {
  return ingredients.reduce((total, r) => {
    if (r.resource.type === ResourceType.Cement) {
      total += r.amount;
    }
    return total;
  }, 0);
}

export function getPercentageFine(ingredients: Ingredient[], recipe?: any) {
  let percentageFine = ingredients.reduce((percentageFine, ingredient) => {
    if ([ResourceType.Cement, ResourceType.Filler].indexOf(ingredient.resource.type as ResourceType) >= 0) {
      percentageFine += getVolume([ingredient]);
    }
    if (ingredient.resource.type === ResourceType.Addition && (ingredient.resource as Addition).sieveTest) {
      // @ts-ignore
      const sieveTest250 = (ingredient.resource as Addition).sieveTest.sieveSteps.find(option => option.sieveSize.size === 0.125);
      if (sieveTest250) {
        percentageFine += getVolume([ingredient]) * getCummulativeRestPercentage((ingredient.resource as Addition).sieveTest as SieveTest, sieveTest250.sieveSize) / 100;
      }
    }
    if (ingredient.resource.type === ResourceType.Extra) {
      percentageFine += getVolume([ingredient]) * (ingredient.resource as Extra).percentageFine / 100;
    }
    return percentageFine;
  }, 0);
  if (recipe) {
    // @ts-ignore
    const hasAirBubbles = ingredients.find(ingredient => ingredient.resource.type === ResourceType.Excipient && (ingredient.resource as Excipient).mainExcipientEffectId === ExcipientEffect.AirBubbleMaker);
    hasAirBubbles && recipe.airPercentage && (percentageFine += recipe.airPercentage * 10 - 20);
  }
  return percentageFine;
}

export function getVolume(ingredients: Array<{ amount: number, resource: { density: number } }>) {
  return ingredients.reduce((volume, r) => volume += (r.amount ? r.amount / (r.resource.density / 1000) : 0), 0);
}

export function getCummulativeRestPercentage(sieveTest: SieveTest, sieveSize: SieveSize) {
  const { startingWeight, sieveSteps } = sieveTest;
  let cummulative = 0;
  sieveSteps.sort((a, b) => a.sieveSize.size - b.sieveSize.size).forEach(s => {
    if (s.sieveSize.size <= sieveSize.size) {
      cummulative += 100 / startingWeight * s.restWeight;
    }
  });
  return cummulative;
}

export function getAbsorption(ingredients: Ingredient[]) {
  return ingredients.reduce((absorption, r) => absorption += r.amount * (r.absorption || (r.resource.type === ResourceType.Addition && (r.resource as Addition).absorption) || (r.resource.type === ResourceType.Excipient && (r.resource as Excipient).absorption) || (r.resource.type === ResourceType.Extra && (r.resource as Extra).absorption) || 0) / 100, 0);
}

export function getWcfAsString(cubeTest: any, revision: any): string {
  let wcf=getWcf(cubeTest, revision)
  if(!wcf)
    return 'Nvt'
  else
    return format(wcf,{ maximumFractionDigits: 3, minimumFractionDigits: 3 })
}

export function getWcf(cubeTest: any, revision: any): number {
  let sampleWeight=calcSampleWeight(cubeTest)
  let wcf=calculateWcf(cubeTest.trayWeightEmpty || 0, cubeTest.trayWeightWet || 0, cubeTest.trayWeightDry || 0,
    sampleWeight, getBinderTotal(revision.recipe.ingredients, Boolean(revision.recipe.attest)),
    getAbsorption(revision.recipe.ingredients))
  return isNaN(wcf) ? 0 : wcf
}

export function calcSampleWeight(cubeTest: CubeTest): number {
  if(cubeTest.cubes.length===0) return 0
  if(!cubeTest.cubes[0].sampleWeight) return 0
  return cubeTest.cubes[0].sampleWeight
}
