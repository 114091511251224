import {
  Box,
  Checkbox,
  Fab,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography
} from '@material-ui/core'
import React, {Fragment, useCallback, useContext, useEffect, useMemo, useReducer, useState} from 'react'
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom'
import { AppContext } from '../App'
import { Loading } from '../components'
import EN206 from '../components/Reports/EN206'
import { DateType, Receipt } from '../types'
import { ReceiptWithCubeTest } from './Sampling'
import moment from 'moment'
import All from '../components/Reports/All'
import Month from '../components/Reports/Month'
import Filter from '../components/Reports/Filter'
import DateFilter from '../components/Reports/DateFilter'
import Day from '../components/Reports/Day'
import {getAll} from "../HTTPClients/RecipeApp/receipts";

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    right: '50%',
    top: '5px',
    zIndex: 2000,
    boxShadow: 'none'
  },
  smallCheckbox: {
    padding: '0',
    marginRight: 9
  }
}))

type Item = { id: string, title: string, count: number }
type Filter = { title: string, getItems: (receipts: Receipt[]) => Item[], compare: (receipt: Receipt, item: Item) => boolean, selected: Item[] }

const initialState: { [key: string]: Filter | boolean } = {
  showConsistency: true,
  showWCF: true,
  showDeviations: false,
  strengthClass: {
    title: 'Sterkte klasse',
    getItems: receipts => receipts.reduce((arr: Array<Item & { sort: number }>, r) => {
      const index = arr.findIndex(i => i.title === (r.revision.recipe.strengthClass ? r.revision.recipe.strengthClass.code : 'LOS'))
      index >= 0 ? (arr[index].count += 1) : arr.push({ id: r.revision.recipe.strengthClass ? r.revision.recipe.strengthClass.id + '' : 'los', title: r.revision.recipe.strengthClass?.code || 'LOS', sort: r.revision.recipe.strengthClass?.cubePressureStrength || 0, count: 1 })
      return arr
    }, []).sort((a, b) => a.sort - b.sort).map(({ sort, ...i }) => i).sort((a, b) => b.count - a.count),
    compare: (receipt, item) => (item.id === 'los' && !receipt.revision.recipe.strengthClass) || item.id === receipt.revision.recipe.strengthClass?.id + '',
    selected: []
  },
  customer: {
    title: 'Klanten',
    getItems: receipts => receipts.reduce((arr: Array<{ id: string, title: string, count: number }>, r) => {
      const index = arr.findIndex(i => i.title === r.customer)
      if(index >= 0)
        arr[index].count += 1
      else {
        if (r.customer !== null) arr.push({id: r.customer || '', title: r.customer || '', count: 1})
      }
      return arr
    }, []).sort((a, b) => b.count - a.count),
    compare: (receipt, item) => item.id === receipt.customer,
    selected: []
  },
  project: {
    title: 'Werken',
    getItems: receipts => receipts.reduce((arr: Array<{ id: string, title: string, count: number }>, r) => {
      const index = arr.findIndex(i => i.title === r.project)
      if(index >= 0)
        arr[index].count += 1
      else {
        if (r.project !== null) arr.push({id: r.project || '', title: r.project || '', count: 1})
      }
      return arr
    }, []).sort((a, b) => b.count - a.count),
    compare: (receipt, item) => item.id === receipt.project,
    selected: []
  }
}

function reducer(state: { [key: string]: Filter | boolean }, { type, payload }: { type: string, payload?: any }) {
  switch (type) {
    case 'TOGGLE_SHOW_DEVIATIONS':
      return { ...state, showDeviations: !state.showDeviations }
    case 'TOGGLE_SHOW_CONSISTENCY':
      return { ...state, showConsistency: !state.showConsistency }
    case 'TOGGLE_SHOW_WCF':
      return { ...state, showWCF: !state.showWCF }
    case 'TOGGLE_SELECTED':
      if (payload.key && payload.selected && ['showConsistency', 'showWCF', 'showDeviations'].indexOf(payload.key) < 0) {
        const index = (state[payload.key] as Filter).selected.findIndex(s => s.id === payload.selected.id)
        if (index >= 0) {
          (state[payload.key] as Filter).selected.splice(index, 1)
        } else {
          (state[payload.key] as Filter).selected.push(payload.selected)
        }
        return { ...state, [payload.key]: { ...(state[payload.key] as Filter), selected: [...(state[payload.key] as Filter).selected] } }
      }
      return state
    case 'UNSELECT_ALL':
      if (payload.key && ['showConsistency', 'showWCF', 'showDeviations'].indexOf(payload.key) < 0) {
        return { ...state, [payload.key]: { ...(state[payload.key] as Filter), selected: [] } }
      }
      return state
    default: return state
  }
}

const titles = {
  en206: 'Rapportage druksterktemetingen',
  all: 'Meetresultaten',
  month: 'Kubusmaandlijst'
}

const Reports: React.FC = () => {
  const { fab, smallCheckbox } = useStyles()
  const { selectedPlant } = useContext(AppContext)
  const { type, period, period2 } = useParams<{ type: string, period?: string, period2?: string }>()
  const [typePrevious, setTypePrevious] = useState('')
  const [periodPrevious, setPeriodPrevious]= useState('')
  const [period2Previous, setPeriod2Previous]= useState('')
  const history = useHistory()
  const { startDate, endDate } = useMemo(() => {
    if (!period) {
      return {
        startDate: moment().startOf('month').toDate(),
        endDate: moment().endOf('day').toDate()
      }
    } else if (period2) {
      return {
        startDate: new Date(period),
        endDate: new Date(period2)
      }
    } else {
      return {
        startDate: moment().month(period).startOf('month').toDate(),
        endDate: moment().month(period).endOf('month').toDate()
      }
    }
  }, [period, period2])
  const [{ showConsistency, showWCF, showDeviations, ...state }, dispatch] = useReducer(reducer, initialState)
  const filters = useMemo(() => Object.keys(state).map(key => ({ ...(state[key] as Filter), key })), [state])
  const [loading, setLoading]=useState(true);
  const [loadedReceipts, setReceipts]=useState([] as Receipt[]);

  const receipts: Array<Receipt | ReceiptWithCubeTest> = useMemo(() => {
    const onlyWithCubeTest = loadedReceipts.length ? loadedReceipts.filter(r => r.cubeTest) as ReceiptWithCubeTest[] : []
    if (type === 'en206') {
      // REC-104
      return onlyWithCubeTest.map(r => ({ ...r, cubeTest: { ...r.cubeTest, cubes: r.cubeTest.cubes.filter(c => moment(c.testDate).isSameOrBefore(endDate)) } }))
    } else if (type === 'month') {
      return loadedReceipts
    } else {
      return onlyWithCubeTest.filter(r => r.cubeTest && moment(r.cubeTest.sampleDate).isSameOrAfter(startDate) && moment(r.cubeTest.sampleDate).isSameOrBefore(endDate))
    }
  }, [loadedReceipts, type, endDate, startDate])

  const renderTitle = useCallback(() => type !== 'day' ? <Box className="report-title" display="none" marginBottom={1}>
    <Typography variant="h5" display="inline">{titles[type as keyof typeof titles]}</Typography>&nbsp;
    <Typography variant="subtitle2" display="inline">
      {moment(startDate).format('YYYY-MM-DD')} t/m {moment(endDate).format('YYYY-MM-DD')}
      {filters.map(({ selected }) => selected.length > 0 ? `, ${selected.map(s => s.title).join(', ')}` : null).join('')}
    </Typography>
  </Box> : null, [filters, startDate, endDate, type])

  const filtered = useMemo(() => {
    return receipts.filter(r => {
      let matchAll = true
      filters.some(({ selected, compare }) => {
        selected.some(s => {
          matchAll = compare(r, s)
          return matchAll
        })
        return !matchAll
      })
      return matchAll
    })
  }, [filters, receipts])

  useEffect(() => {
    if(typePrevious!==type || periodPrevious!==period || period2Previous!==period2)
      setLoading(true)

    if(typePrevious!==type)
      setTypePrevious(type)
    if(periodPrevious!==period) {
      // @ts-ignore
      setPeriodPrevious(period)
    }
    if(period2Previous!==period2) {
      // @ts-ignore
      setPeriod2Previous(period2)
    }
  },[type, period, period2, typePrevious, periodPrevious, period2Previous, setLoading])

  useEffect(() => {
    if(selectedPlant===undefined) return
    if(!loading) return

    let dateType = type==='month' ? DateType.ReceiptDate : type==='en206' ? DateType.TestDate : DateType.SampleDate;
    let useStart = new Date(startDate.getTime())
    let useEnd = new Date(endDate.getTime())

    if(type==='en206') {
      if(loadedReceipts.length>0) {
        // Skip reloading receipts while still viewing en206 reports
        setLoading(false);
        return
      }
      // Get all receipts
      useStart.setFullYear(2000,0,1)
      useEnd.setFullYear(new Date().getFullYear() + 1,0,1)
    }

    // @ts-ignore
    getAll(selectedPlant.id, dateType, useStart, useEnd).then(
      function(response: any) {
        if(type==='en206') {
          let receipts=prepareEn206(response.data.data);
          setReceipts(receipts);
        } else
          setReceipts(response.data.data);
        setLoading(false);
      }
    )
  },[loading, selectedPlant, loadedReceipts, type, startDate, endDate, setReceipts, setLoading])

  function prepareEn206(receipts: any[]) {
    for(let receipt of receipts) {
      // Sort environments on ID descending
      receipt.revision.recipe.environmentClasses.sort((a: any, b: any) => b.id - a.id);
      // Only first environment
      receipt.revision.recipe.environmentClasses=new Array(receipt.revision.recipe.environmentClasses[0]);
    }
    return receipts;
  }

  const handleDateRangeChange = useCallback((range: { startDate: Date, endDate: Date }) => {
    if (moment(range.startDate).isSame(moment(range.startDate).startOf('month'), 'day') && moment(range.endDate).isSame(moment(range.startDate).endOf('month'), 'day')) {
      history.push(`/reports/${type}/${moment(range.startDate).format('MMMM')}`)
    } else {
      history.push(`/reports/${type}/${moment(range.startDate).format('YYYY-MM-DD')}/${moment(range.endDate).format('YYYY-MM-DD')}`)
    }
  }, [history, type])

  return <Grid container={true} style={{ flex: 1, overflow: 'hidden' }}>
    <Grid id="sidebar" item={true} style={{ display: 'flex', flexDirection: 'column', height: '100%', borderRight: '1px solid #444', overflow: 'auto' }}>
      <DateFilter startDate={startDate} endDate={endDate} onChange={handleDateRangeChange} />
      {type === 'en206' && <List dense={true}>
        <ListItem>
          <ListItemText primary="Weergave opties" />
        </ListItem>
        <ListItem button={true} onClick={() => dispatch({ type: 'TOGGLE_SHOW_CONSISTENCY' })}>
          <Checkbox className={smallCheckbox} checked={showConsistency as boolean} />
          <ListItemText primary="Consistentiemetingen" />
        </ListItem>
        <ListItem button={true} onClick={() => dispatch({ type: 'TOGGLE_SHOW_WCF' })}>
          <Checkbox className={smallCheckbox} checked={showWCF as boolean} />
          <ListItemText primary="WCF metingen" />
        </ListItem>
        <ListItem button={true} onClick={() => dispatch({ type: 'TOGGLE_SHOW_DEVIATIONS' })}>
          <Checkbox className={smallCheckbox} checked={showDeviations as boolean} />
          <ListItemText primary="Toon afwijkingen" />
        </ListItem>
      </List>}
      {filters.map(({ title, getItems, selected, key }) =>
        <Fragment key={key}>
          {!(type==='en206' && (key==='customer' || key==='project')) ?
            <Filter key={key} title={title} items={getItems(receipts)} selected={selected} reportType={type}
              toggleSelected={selected => dispatch({ type: 'TOGGLE_SELECTED', payload: { key, selected } })}
              unselectAll={() => dispatch({ type: 'UNSELECT_ALL', payload: { key } })}
            /> : <Fragment></Fragment>
          }
        </Fragment>
      )}
    </Grid>
    <Grid item={true} xs={true} style={{ overflow: 'auto', height: '100%' }}>
      {renderTitle()}
      {loading ? <Loading /> : <Switch>
        <Route path="/reports/en206"><EN206 receipts={filtered as ReceiptWithCubeTest[]} showConsistency={showConsistency as boolean} showWCF={showWCF as boolean} showDeviations={showDeviations as boolean} startDate={startDate} endDate={endDate} /></Route>
        <Route path="/reports/all"><All receipts={filtered as ReceiptWithCubeTest[]} /></Route>
        <Route path="/reports/month"><Month receipts={filtered} startDate={startDate} endDate={endDate} /></Route>
        <Route path="/reports/day"><Day receipts={filtered as ReceiptWithCubeTest[]} /></Route>
        <Redirect to="/reports/en206" />
      </Switch>}
    </Grid>
    <Fab className={fab} color="primary" onClick={() => window.print()}><Icon>print</Icon></Fab>
  </Grid>
}

export default Reports
