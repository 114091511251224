import React, { Fragment, useCallback, useMemo } from 'react'
import { InputAdornment, Typography, Box } from '@material-ui/core'
import NumericTextField from '../NumericTextField'
import { Alert } from '@material-ui/lab'
import { format } from 'react-numberinput-formatter'
import useAuthorized from '../../useAuthorized'
import { ReceiptWithCubeTest } from '../../containers/Sampling'
import {getAbsorption, getBinderTotal} from "../../calculations/recipe";

export function calculateWcf(trayWeightEmpty: number, trayWeightWet: number, trayWeightDry: number, weight: number, binderTotal: number, absorption: number) {
  const drySample = trayWeightDry - trayWeightEmpty
  const wetSample = trayWeightWet - trayWeightEmpty
  const moisture = wetSample - drySample
  const water = (weight / 3.375) / wetSample * moisture
  return (water - absorption) / binderTotal
}

const CubeTestWcfStep: React.FC<{ receipt: ReceiptWithCubeTest, onChange: (receipt: ReceiptWithCubeTest) => void }> = ({ receipt, onChange }) => {
  const { trayWeightEmpty, trayWeightWet, trayWeightDry } = receipt.cubeTest
  const weight = receipt.cubeTest.cubes.length>0 ? receipt.cubeTest.cubes[0].sampleWeight : 0

  receipt.revision.recipe.absorption=getAbsorption(receipt.revision.recipe.ingredients)
  receipt.revision.recipe.binderTotal=getBinderTotal(receipt.revision.recipe.ingredients, Boolean(receipt.revision.recipe.attest))

  const { binderTotal, absorption } = receipt.revision.recipe
  const canEdit = useAuthorized(['update:cubetests'])
  const wcf = useMemo(() => {
    return trayWeightEmpty && trayWeightWet && trayWeightDry && weight && binderTotal ? calculateWcf(trayWeightEmpty, trayWeightWet, trayWeightDry, weight, binderTotal, absorption) : 0
  }, [weight, trayWeightEmpty, trayWeightWet, trayWeightDry, binderTotal, absorption])

  const handleChange = useCallback((key: 'trayWeightEmpty' | 'trayWeightWet' | 'trayWeightDry', value: number) => {
    if (receipt.cubeTest) {
      onChange({ ...receipt, cubeTest: { ...receipt.cubeTest, [key]: value } })
    }
  }, [receipt, onChange])

  return (
    <Fragment>
      <Box display="flex" marginBottom={2}>
        <Alert variant="outlined" severity={wcf > receipt.revision.recipe.wbf ? 'error' : 'info'} style={{ marginRight: 8 }}>
          <Typography variant="body2">Berekende WCF: {format(wcf, { maximumFractionDigits: 3 })}</Typography>
        </Alert>
        <Alert variant="outlined" severity="info" style={{ marginRight: 8 }}>
          <Typography variant="body2">WCF recept: {format(receipt.revision.recipe.wbf, { maximumFractionDigits: 3 })}</Typography>
        </Alert>
        <Alert variant="outlined" severity="info">
          <Typography variant="body2">Totaal bindmiddel: {format(binderTotal, { maximumFractionDigits: 1 })}</Typography>
        </Alert>
      </Box>
      <Box display="flex">
        <NumericTextField
          label="Gewicht bak leeg"
          onChange={e => handleChange('trayWeightEmpty', e.target.value as number)}
          value={typeof trayWeightEmpty !== 'undefined' && trayWeightEmpty !== null ? trayWeightEmpty : ''}
          InputProps={{
            endAdornment: <InputAdornment position="end">g</InputAdornment>
          }}
          style={{ marginRight: 16 }}
          disabled={!canEdit}
        />
        <NumericTextField
          label="Monster nat + bak"
          onChange={e => handleChange('trayWeightWet', e.target.value as number)}
          value={typeof trayWeightWet !== 'undefined' && trayWeightWet !== null ? trayWeightWet : ''}
          InputProps={{
            endAdornment: <InputAdornment position="end">g</InputAdornment>
          }}
          style={{ marginRight: 16 }}
          disabled={!canEdit}
        />
        <NumericTextField
          label="Monster droog + bak"
          onChange={e => handleChange('trayWeightDry', e.target.value as number)}
          value={typeof trayWeightDry !== 'undefined' && trayWeightDry !== null ? trayWeightDry : ''}
          InputProps={{
            endAdornment: <InputAdornment position="end">g</InputAdornment>
          }}
          disabled={!canEdit}
        />
      </Box>
    </Fragment>
  )
}

export default CubeTestWcfStep
