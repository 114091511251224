import React, { useState, Fragment, useEffect, ReactNode } from 'react'
import { makeStyles, Theme, Avatar, Icon, IconButton, Drawer, Card, CardHeader, Table, TableBody, TableRow, TableCell, useMediaQuery, Link } from '@material-ui/core'
import { CubeIcon } from '../../icons'
import NumberFormat, { NumberFormatProps } from 'react-number-format'
import moment from 'moment'
import { ReceiptWithCubeTest } from '../../containers/Sampling'
import { format } from 'react-numberinput-formatter'
import { useHistory } from 'react-router-dom'
import {getBinderTotal, getPercentageFine, getRecipeDensity} from "../../calculations/recipe";

const CustomNumberFormat: React.FC<NumberFormatProps> = props => <NumberFormat
  {...props}
  displayType="text"
  decimalSeparator=","
  thousandSeparator="."
/>

const useCubeDetailStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: 1000,
  },
  drawerPaper: {
    maxWidth: 480,
    boxSizing: 'border-box',
    paddingTop: 64,
    zIndex: 1000
  },
  toggleButton: {
    position: 'absolute',
    top: 72,
    right: 16
  }
}))

const getInfo: (receipt: ReceiptWithCubeTest, history: { push: (path: string) => void }) => Array<{ avatar: JSX.Element, subheader: string, details: Array<{ title: string, value: ReactNode }> }> = ({ id, amount, customer, project, revision, cubeTest }, history) => [
  {
    avatar: <Avatar><CubeIcon color="inherit" /></Avatar>,
    subheader: "Kubustest details",
    details: [
      { title: "Kubusnummer", value: <Link color={"secondary"} onClick={() => history.push(`/sample/${cubeTest.cubeNumber}`)} style={{ cursor: 'pointer', textDecoration: 'none' }}>{cubeTest.cubeNumber}</Link> },
      { title: "Monsternamedatum", value: moment(cubeTest.sampleDate).format('D MMMM YYYY') },
      { title: "Temperatuur monster", value: <span><CustomNumberFormat value={cubeTest.temperature || undefined} /> <sup>&deg;</sup>C</span> },
      { title: "Volumieke massa", value: cubeTest.weight ? <span><CustomNumberFormat value={cubeTest.weight / 3.375} decimalScale={1} /> kg/m<sup>3</sup></span> : null },
      ...revision.recipe.consistencyClass.consistencyTestTypes.map(consistencyTestType => {
        const test = cubeTest.consistencyTests.find(ct => ct.consistencyTestType.id === consistencyTestType.id)
        return {
          title: consistencyTestType.code.charAt(0).toUpperCase() + consistencyTestType.code.slice(1),
          value: test ? test.values.map(v => v.value + ' ' + v.parameter.unit).join(', ') : null
        }
      })
    ]
  },
  {
    avatar: <Avatar><Icon color="inherit">assignment</Icon></Avatar>,
    subheader: "Order details",
    details: [
      { title: 'Ordernummer', value: id + '' },
      { title: 'Klant', value: customer + '' },
      { title: 'Werk', value: project + '' },
      { title: "Aantal kuub", value: <span><CustomNumberFormat value={amount} /> m<sup>3</sup></span> }
    ]
  },
  {
    avatar: <Avatar><Icon color="inherit">assignment</Icon></Avatar>,
    subheader: 'Recept',
    details: [
      { title: "Receptnummer", value: <span><Link color={"secondary"} style={{ display: 'flex', whiteSpace: 'nowrap', alignItems: 'center', cursor: 'pointer', textDecoration: 'none' }} href={`${window.location.origin}/receptuur/recipe/${revision.recipe.id}`} target="_BLANK">{revision.recipe.id}&nbsp;&nbsp;<Icon>screen_share</Icon></Link></span> },
      { title: "Receptnaam", value: revision.recipe.recipeName },
      { title: "WBF", value: <span>{format(revision.recipe.wbf, { minimumFractionDigits: 3 })}</span> },
      { title: "Volumieke massa", value: <span>{format(getRecipeDensity(revision.recipe.ingredients),{ maximumFractionDigits: 1, useGrouping: true })} kg/m<sup>3</sup></span> },
      { title: "Hoeveelheid bindmiddel", value: <span>{format(getBinderTotal(revision.recipe.ingredients, Boolean(revision.recipe.attest)),{ maximumFractionDigits: 2 })} kg</span> },
      { title: "Fijnmateriaal", value: <span>{format(getPercentageFine(revision.recipe.ingredients, revision.recipe),{ maximumFractionDigits: 1 })} L</span> },
      { title: "Luchtgehalte", value: <span>{format(revision.recipe.airPercentage,{ maximumFractionDigits: 2 })}%</span> }
    ]
  }
]

const CubeDetails: React.FC<{ receipt: ReceiptWithCubeTest }> = ({ receipt }) => {
  const minWidth = useMediaQuery('(min-width:1400px)')
  const [open, setOpen] = useState(minWidth)
  const { root, drawerPaper, toggleButton } = useCubeDetailStyles()
  const history = useHistory()

  if(receipt.project===null) receipt.project = 'Nvt' // Clear project when null

  useEffect(() => {
    setOpen(minWidth)
  }, [minWidth])

  return (
    <Fragment>
      <div className={toggleButton}>
        <IconButton onClick={() => setOpen(true)}>
          <Icon>info</Icon>
        </IconButton>
      </div>
      <Drawer variant="persistent" open={open} anchor="right" className={root} classes={{ paper: drawerPaper }} >
        <IconButton onClick={() => setOpen(false)} className={toggleButton}>
          <Icon>close</Icon>
        </IconButton>
        <Card style={{ height: '100%', overflow: 'auto' }}>
          {getInfo(receipt, history).map(({ avatar, subheader, details }, k) => (
            <Fragment key={k}>
              <CardHeader avatar={avatar} subheader={subheader} />
              <Table style={{ marginBottom: 16 }}>
                <TableBody>
                  {details.map(({ title, value }, i) => <TableRow key={i}>
                    <TableCell>{title}</TableCell>
                    <TableCell>{value}</TableCell>
                  </TableRow>)}
                </TableBody>
              </Table>
            </Fragment>
          ))}
        </Card>
      </Drawer>
    </Fragment>
  )
}

export default CubeDetails
