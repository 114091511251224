import React, { useMemo } from 'react'
import {Receipt, CubeTest, Cube} from '../../types'
import { ListItem, Badge, Tooltip, Icon, Typography, ListItemSecondaryAction, IconButton, ListItemText } from '@material-ui/core'
import { CubeIcon } from '../../icons'
import useAuthorized from '../../useAuthorized'

type CubeTestListItemProps = {
  receipt: Receipt
  selected?: boolean
  onSelect: () => void
  onDelete: () => void
  isLast?: boolean
}

const CubeTestListItem: React.FC<CubeTestListItemProps> = ({ selected, receipt, onSelect, onDelete, isLast, ...props }) => {
  const { cubeNumber, temperature, weight, consistencyTests } = receipt.cubeTest as CubeTest
  const cubes: any=receipt.cubeTest?.cubes
  const canDelete = useAuthorized(['delete:cubetests'])

  const missingWeights=cubes===undefined ? false : cubes.findIndex((c: Cube)=>c.sampleWeight)===-1

  const errors = useMemo(() => {
    const errors: string[] = []
    const testsWithValue = consistencyTests ? consistencyTests.filter(item => receipt.revision.recipe.consistencyClass.consistencyTestTypes.findIndex(c => c.id === item.consistencyTestType.id) >= 0 && item.values.filter(value => value.value && value.value > 0).length > 0) : []
    !temperature && errors.push('Vul de temperatuur in')
    missingWeights && errors.push('Vul het gewicht van de kubus in')
    testsWithValue.length === 0 && errors.push('Vul een consistentiemeting in')
    return errors
  }, [temperature, weight, consistencyTests, receipt])

  return (
    <ListItem onClick={onSelect} button={true} selected={selected}>
      <div style={{ marginRight: 16, display: 'flex', flexDirection: 'column', alignItems: 'center', color: '#FFF' }}>
        <Badge color="error" badgeContent={errors.length > 0 ?
          <Tooltip title={<div style={{ display: 'flex', flexDirection: 'column' }}>{errors.map((error, k) => <span key={k}>{error}</span>)}</div>}><span>{errors.length}</span></Tooltip> : null
        }><CubeIcon color="inherit" /></Badge>
        <Typography variant="subtitle2" style={{ whiteSpace: 'nowrap' }}>{cubeNumber}</Typography>
      </div>
      <ListItemText primary="Bonnummer" secondary={receipt.id} />
      {
        isLast && canDelete && (
          <ListItemSecondaryAction>
            <IconButton onClick={onDelete}>
              <Icon>delete</Icon>
            </IconButton>
          </ListItemSecondaryAction>
        )
      }
    </ListItem >
  )
}

export default CubeTestListItem
